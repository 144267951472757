import React, { useState, useEffect } from "react";
import ReactGA from 'react-ga4';
// import axios from "axios";
import ReactMarkdown from "react-markdown";
import axiosInstance from "../utils/axiosInstance";
import ExpiredSessionModal from "../utils/ExpiredSessionModal";
import config from '../config';
import "./Chat.css";

// const API_URL = process.env.REACT_APP_INDEXER_BASE_URL;

const Chat = ({ token, clientName }) => {
  const [query, setQuery] = useState("");
  const [chatLog, setChatLog] = useState(() => {
    const savedChatLog = sessionStorage.getItem("chatLog");
    return savedChatLog ? JSON.parse(savedChatLog).slice(-3) : [];
  });
  const [loading, setLoading] = useState(false);
  const [isSessionExpired, setIsSessionExpired] = useState(false);

  useEffect(() => {
    window.onbeforeunload = () => {
      sessionStorage.removeItem("chatLog");
    };
  }, []);

  useEffect(() => {
    const truncatedChatLog = chatLog.slice(-3);  // Keep only the last 3 entries
    sessionStorage.setItem("chatLog", JSON.stringify(truncatedChatLog));
  }, [chatLog]);
  
  const handleQuery = async (e) => {
    e.preventDefault();
    if (!query.trim()) return;

    setChatLog([...chatLog, { query, response: null }]);
    setQuery("");
    setLoading(true);
    ReactGA.initialize(config.GOOGLE_ANALYTICS_TRACKING_ID);
    ReactGA.event({
      category: 'Chat',
      action: 'Submit Query',
      label: query,
      nonInteraction: false
    });

    try {
      const response = await axiosInstance.post(
        `/generate_answer`,
        { query: query, history: chatLog.slice(-3) },
        { headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` } }
      );

      setChatLog((prevChatLog) => {
        const updatedLog = [...prevChatLog];
        updatedLog[updatedLog.length - 1].response = response.data.response;
        return updatedLog;
      });
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setIsSessionExpired(true);
      }
      console.error("Error generating answer:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSignIn = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("clientName");
    // window.location.href = "https://app.raglabs.co";
    window.location.reload();
  };

  const handleHome = () => {
    window.location.href = "https://raglabs.co";
  };

  if (isSessionExpired) {
    return <ExpiredSessionModal onSignIn={handleSignIn} onHome={handleHome} />;
  }

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleQuery(e);
    }
  };

  return (
    <div className="chat-box"> {/* Removed redundant chat-container */}
      <h2 className="chat-title">Chat with Raglabs</h2>
      <div className="chat-log">
        {chatLog.map((entry, index) => (
          <div key={index} className="chat-message">
            <p className="chat-user">
              <strong>You:</strong> {entry.query}
            </p>
            <p className="chat-bot">
              <strong>RAG:</strong>{" "}
              {entry.response ? (
                <ReactMarkdown>{entry.response}</ReactMarkdown>
              ) : (
                <em>Waiting for response...</em>
              )}
            </p>
          </div>
        ))}
        {loading && (
          <div className="chat-bot-typing">
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
          </div>
        )}
      </div>
      <form onSubmit={handleQuery} className="chat-form">
        <input
          type="text"
          placeholder="Enter your query"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          onKeyPress={handleKeyPress}
          className="chat-input"
        />
        <button type="submit" className="chat-button">Send</button>
      </form>
    </div>
  );
};

export default Chat;

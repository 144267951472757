import React from 'react';
import './ExpiredSessionModal.css'; // For styling the modal

const ExpiredSessionModal = ({ onSignIn, onHome }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-box">
        <h2>Session Expired</h2>
        <p>Your session has expired. Please sign in again or go to the home page.</p>
        <div className="modal-buttons">
          <button className="modal-btn-signin" onClick={onSignIn}>
            Sign In
          </button>
          <button className="modal-btn-home" onClick={onHome}>
            Home
          </button>
        </div>
      </div>
    </div>
  );
};

export default ExpiredSessionModal;

import React, { useState } from "react";
import axios from "axios";
import logo from './main_logo.svg';
import "./Login.css";

const API_URL = process.env.REACT_APP_INDEXER_BASE_URL;

const Login = ({ setToken, setClientName }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage("");
    try {
      const response = await axios.post(`${API_URL}/login`, {
        username,
        password,
      });
      setToken(response.data.token);
      setClientName(response.data.client_name);
    } catch (error) {
      setErrorMessage("Invalid username or password");
    } finally {
      setLoading(false)
    }
  };

  return (
    <div className="login-page">
      <div className="logo-section">
        <img src={logo} alt="raglabs.co logo" className="logo" />
        <h1 className="logo-text">Your company's own ChatGPT</h1>
      </div>
      <div className="login-section">
        <h2 className="login-title">login</h2>
        {loading ? (
          <div className="loading-spinner">
            <div className="spinner"></div> {/* Simple loading spinner */}
            <p className="loading-text">Loading...</p>
          </div>
        ) : (
        <form onSubmit={handleLogin} className="login-form">
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="login-input"
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="login-input"
          />
          <button type="submit" className="login-button">Login with Username</button>
        </form>
        )}
        {errorMessage && <p className="error">{errorMessage}</p>}
      </div>
    </div>
  );
};

export default Login;

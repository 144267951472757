// import axios from "axios";

// const axiosInstance = axios.create({
//   baseURL: process.env.REACT_APP_INDEXER_BASE_URL,  // Your base API URL
//   headers: {
//     "Content-Type": "application/json",
//   },
// });

// axiosInstance.interceptors.response.use(
//   (response) => response,  // Return the response if successful
//   (error) => {
//     if (error.response && error.response.status === 401) {
//       localStorage.removeItem("token");
//       localStorage.removeItem("clientName");

//       alert("Your session has expired. Please log in again.");
//       window.location.reload();
//     }
//     return Promise.reject(error);
//   }
// );

// export default axiosInstance;

import axios from "axios";

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_INDEXER_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Interceptor to handle expired token
axiosInstance.interceptors.response.use(
  (response) => response,  // Return the response if successful
  (error) => {
    if (error.response && error.response.status === 401) {
      // Trigger the session expiration callback in App.js
      const sessionExpireHandler = window.handleSessionExpire;
      if (sessionExpireHandler) {
        sessionExpireHandler();  // Trigger session expiration
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;

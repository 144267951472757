// import React, { useState } from "react";
// import Login from "./components/Login";
// import Chat from "./components/Chat";

// function App() {
//   const [token, setToken] = useState(null);
//   const [clientName, setClientName] = useState(null);

//   if (!token) {
//     return <Login setToken={setToken} setClientName={setClientName} />;
//   }

//   return <Chat token={token} clientName={clientName} />;
// }

// export default App;


// import React, { useState, useEffect } from "react";
// import Login from "./components/Login";
// import Chat from "./components/Chat";

// function App() {
//   const [token, setToken] = useState(null);
//   const [clientName, setClientName] = useState("");

//   useEffect(() => {
//     const savedToken = localStorage.getItem("token");
//     const savedClientName = localStorage.getItem("client_name");

//     if (savedToken && savedClientName) {
//       setToken(savedToken);
//       setClientName(savedClientName);
//     }
//   }, []);

//   const handleLogin = (token, clientName) => {
//     localStorage.setItem("token", token);
//     localStorage.setItem("client_name", clientName);
//     setToken(token);
//     setClientName(clientName);
//   };

//   const handleLogout = () => {
//     localStorage.removeItem("token");
//     localStorage.removeItem("client_name");
//     setToken(null);
//     setClientName("");
//   };

//   return (
//     <div className="App">
//       {/* If token exists, show chat; otherwise, show login screen */}
//       {token ? (
//         <>
//           <button className="logout-button" onClick={handleLogout}>
//             Logout
//           </button>
//           <Chat token={token} clientName={clientName} />
//         </>
//       ) : (
//         <Login onLogin={handleLogin} />
//       )}
//     </div>
//   );
// }

// export default App;

// import React, { useState, useEffect } from "react";
// import Login from "./components/Login";
// import Chat from "./components/Chat";

// function App() {
//   const [token, setToken] = useState(null);
//   const [clientName, setClientName] = useState("");

//   // Check if token exists in localStorage on page load
//   useEffect(() => {
//     const savedToken = localStorage.getItem("token");
//     const savedClientName = localStorage.getItem("client_name");

//     if (savedToken && savedClientName) {
//       setToken(savedToken);
//       setClientName(savedClientName);
//     }
//   }, []);

//   // Handle login and store token and clientName in localStorage
//   const handleLogin = (token, clientName) => {
//     localStorage.setItem("token", token);
//     localStorage.setItem("client_name", clientName);
//     setToken(token);
//     setClientName(clientName);
//   };

//   // Handle logout and clear localStorage
//   const handleLogout = () => {
//     localStorage.removeItem("token");
//     localStorage.removeItem("client_name");
//     setToken(null);
//     setClientName("");
//   };

//   return (
//     <div className="App">
//       {/* If token exists, show chat; otherwise, show login screen */}
//       {token ? (
//         <>
//           <button className="logout-button" onClick={handleLogout}>
//             Logout
//           </button>
//           <Chat token={token} clientName={clientName} />
//         </>
//       ) : (
//         <Login setToken={setToken} setClientName={setClientName} />
//       )}
//     </div>
//   );
// }

// export default App;

// import React, { useState, useEffect } from "react";
// import Login from "./components/Login";
// import Chat from "./components/Chat";

// function App() {
//   const [token, setToken] = useState(null);
//   const [clientName, setClientName] = useState("");

//   // Check if token exists in localStorage on page load
//   useEffect(() => {
//     const savedToken = localStorage.getItem("token");
//     const savedClientName = localStorage.getItem("client_name");

//     if (savedToken && savedClientName) {
//       setToken(savedToken);
//       setClientName(savedClientName);
//     }
//   }, []);

//   // Handle logout and clear localStorage
//   const handleLogout = () => {
//     localStorage.removeItem("token");
//     localStorage.removeItem("client_name");
//     setToken(null);
//     setClientName("");
//   };

//   return (
//     <div className="App">
//       {/* If token exists, show chat; otherwise, show login screen */}
//       {token ? (
//         <>
//           <button className="logout-button" onClick={handleLogout}>
//             Logout
//           </button>
//           <Chat token={token} clientName={clientName} />
//         </>
//       ) : (
//         <Login setToken={setToken} setClientName={setClientName} />
//       )}
//     </div>
//   );
// }

// export default App;

// import React, { useState, useEffect } from "react";
// import Login from "./components/Login";
// import Chat from "./components/Chat";

// function App() {
//   const [token, setToken] = useState(null);
//   const [clientName, setClientName] = useState("");

//   // Check if token exists in localStorage on page load
//   useEffect(() => {
//     const savedToken = localStorage.getItem("token");
//     const savedClientName = localStorage.getItem("client_name");

//     if (savedToken && savedClientName) {
//       setToken(savedToken);
//       setClientName(savedClientName);
//     }
//   }, []);

//   // Handle login and store token and clientName in localStorage
//   const handleLogin = (token, client_name) => {
//     localStorage.setItem("token", token);
//     localStorage.setItem("client_name", client_name);
//     setToken(token);
//     setClientName(client_name);
//   };

//   // Handle logout and clear localStorage
//   const handleLogout = () => {
//     localStorage.removeItem("token");
//     localStorage.removeItem("client_name");
//     setToken(null);
//     setClientName("");
//   };

//   return (
//     <div className="App">
//       {/* If token exists, show chat; otherwise, show login screen */}
//       {token ? (
//         <>
//           <button className="logout-button" onClick={handleLogout}>
//             Logout
//           </button>
//           <Chat token={token} clientName={clientName} />
//         </>
//       ) : (
//         <Login onLogin={handleLogin} />
//       )}
//     </div>
//   );
// }

// export default App;


// import React, { useState, useEffect } from "react";
// import Login from "./components/Login";
// import Chat from "./components/Chat";
// import './App.css';

// function App() {
//   const [token, setToken] = useState(null);
//   const [clientName, setClientName] = useState("");

//   useEffect(() => {
//     const savedToken = localStorage.getItem("token");
//     const savedClientName = localStorage.getItem("client_name");

//     if (savedToken && savedClientName) {
//       setToken(savedToken);
//       setClientName(savedClientName);
//     }
//   }, []);

//   const handleLogin = (token, client_name) => {
//     localStorage.setItem("token", token);
//     localStorage.setItem("client_name", client_name);
//     setToken(token);
//     setClientName(client_name);
//   };

//   const handleLogout = () => {
//     localStorage.removeItem("token");
//     localStorage.removeItem("client_name");
//     setToken(null);
//     setClientName("");
//   };

//   return (
//     <div className="App">
//       {token ? (
//         <div className="chat-container">
//           <button className="logout-button" onClick={handleLogout}>
//             Logout
//           </button>
//           <div className="chat-box">
//             <h2 className="chat-title">Chat with RAG System</h2>
//             <Chat token={token} clientName={clientName} />
//           </div>
//         </div>
//       ) : (
//         <Login onLogin={handleLogin} />
//       )}
//     </div>
//   );
// }

// export default App;

import React, { useState, useEffect } from "react";
import Chat from "./components/Chat";
import Login from "./components/Login";
import "./App.css";  // Main styling
import ReactGA from 'react-ga4';
import config from './config';

function App() {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [clientName, setClientName] = useState(localStorage.getItem("clientName"));

  useEffect(() => {
    ReactGA.initialize(config.GOOGLE_ANALYTICS_TRACKING_ID);
    ReactGA.send("pageview");
    if (token) {
      localStorage.setItem("token", token);
      localStorage.setItem("clientName", clientName);
    }
  }, [token, clientName]);

  const handleLogout = () => {
    ReactGA.event({
      category: 'User',
      action: 'Logout',
      nonInteraction: false
    });
    setToken(null);
    setClientName(null);
    localStorage.removeItem("token");
    localStorage.removeItem("clientName");
    sessionStorage.removeItem("chatLog");
  };

  if (!token) {
    return <Login setToken={setToken} setClientName={setClientName} />;
  }

  return (
    <div className="app-container"> {/* Renamed the container */}
      <button onClick={handleLogout} className="logout-button">
        Logout
      </button>
      <Chat token={token} clientName={clientName} />
    </div>
  );
}

export default App;
